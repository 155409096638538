<template>
        <defaultSec :title="'活动参与查询'" :returnState="true">
            <el-tabs v-model="activeName" type="border-card"  @tab-click="initList">
                <el-tab-pane label="活动记录" name="0">
                    <fit :fit="fit"></fit>
                </el-tab-pane>
                <el-tab-pane label="团购" name="1"  >
                    <teamBuy :teamBuy="teamBuy"></teamBuy>
                </el-tab-pane>
            </el-tabs>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import fit from '@/components/active/fit'
    import teamBuy from '@/components/active/teamBuy'
    export default {
        data() {
            return {
                activeName:'0'
            }
        },
        components:{
            defaultSec,tablePagination,fit,teamBuy
        },
        mounted(){
            this.initList();
        },
        methods: {
            initList() {
            }
        }

    }
</script>


<style scoped>

</style>